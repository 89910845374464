import { createGlobalStyle } from "styled-components";
export const GlobalStyles = createGlobalStyle`
    html {
      overflow: hidden;;
      height: 100%;
    }
    body {
      font-family: -apple-system,BlinkMacSystemFont,Roboto,Oxygen-Sans,sans-serif,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Hiragino Kaku Gothic Pro,Meiryo,Arial,Apple Color Emoji,Segoe;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      margin: 0;
      overflow: hidden;
      -webkit-user-drag: none; 
      user-select: none;
      height: 100%;
      color: ${({ theme }) => theme.text};
      background: ${({ theme }) => theme.body} !important;
      transition: all 0.50s linear;
    }
    a, a:link, a:visited {
      user-select: none;
      -webkit-user-drag: none;
      cursor: pointer;
      transition: all 0.3s ease;
      text-decoration: none;
      color: #5e6977;
    }
    a:hover {
      color: #43484d;
    }
    a:active {
      color: #ce2e21;
    }

    textarea,
    select,
    input,
    button
    {
      outline: none;
    }
    hr {
      margin: 1em;
      border: .5px solid lightgray;
      width: 100%;
    }
    h3
    {
      user-select: none;
      font-size: 1.1em;
      font-weight: normal;
    }
    form
    {
      width: 20em;
      margin-bottom: 1em;
      text-align: center;
    }
    form input,
    form select
    {
      font-size: 1em;
      margin: .5em .5em .5em 0;
      padding: .5em;
      border: 2px solid lightgrey;
      border-radius: .5em;
      outline: none;
      background: white;
    }

    form select
    {
      height: 2.35em;
    }
    ::-webkit-input-placeholder { /* WebKit browsers */
      text-transform: none;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      text-transform: none;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      text-transform: none;
    }
    :-ms-input-placeholder { /* Internet Explorer 10+ */
      text-transform: none;
    }
    ::placeholder { /* Recent browsers */
      text-transform: none;
    }
`;

export const lightMode = {
  greyLogo: "#022020",
  blueLogo: "#006bd6",
  goldLogo: "#ffaa00",
  redLogo: "#ff154d",
  black: "#000000",
  body: "#f0f5f9",
  darkTint: "rgba(0,0,0,0.6)",
  deepBlue: "#0662FF",
  deepGreen: "#2AC745",
  deepPurple: "#7644FF",
  deepRed: "#e20c44",
  deepYellow: "#FF9750",
  defaultBoxShadow: "0px 3px 10px",
  defaultTransition: "all 0.4s ease",
  fastTransition: "all 0.3s ease",
  font: "'Lato', sans-serif",
  grey0: "#393e42",
  grey1: "#43484d",
  grey2: "#5e6977",
  grey3: "#86939e",
  grey4: "#bdc6cf",
  grey5: "#e1e8ee",
  grey6: "#f0f5f9",
  greyA: "#202325",
  lightTint: "rgba(255,255,255,0.8)",
  lightestGrey: "hsla(0, 0%, 0%, 0.05)",
  midBlue: "#369bffff",
  midGreen: "#3CD55D",
  midPurple: "#8F5CFF",
  midRed: "#FC4E4E",
  midYellow: "#ffd26e",
  midYellowAccent: "#ffeabd",
  notSubtleBoxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
  pastelBlue: "#E6F2FF",
  pastelGreen: "#EBFBEE",
  pastelPurple: "#F4EEFF",
  pastelRed: "#FFEDED",
  pastelRedDarkMode: "#FFEDED",
  pastelYellowDarkMode: "#FFF8EF",
  pastelYellow: "#FFF8EF",
  skeletonBackgroundColor: "rgba(100,100,120,0.2)",
  skeletonForegroundColor: "rgba(180,180,200,0.2)",
  slowTransition: "all 1.5s ease",
  smallBoxShadow: "0px 3px 5px",
  sortOfSubtleBoxShadow: "0px 3px 5px rgba(0,0,0,0.1)",
  subtleBoxShadow: "0px 3px 5px rgba(0,0,0,0.05)",
  subtleDarkTint: "rgba(0,0,0,0.1)",
  sunYellow: "#5e6977",
  veryLightGrey: "#f5f5f5",
  white: "#ffffff",
};

export const darkMode = {
  greyLogo: "#FFF",
  blueLogo: "#FFF",
  goldLogo: "#FFF",
  redLogo: "#FFF",
  black: "#ffffff",
  body: "#181818",
  darkTint: "rgba(255,255,255,0.8)",
  deepBlue: "#0662FF",
  deepGreen: "#2AC745",
  deepPurple: "#7644FF",
  deepRed: "#e20c44",
  deepYellow: "#FF9750",
  defaultBoxShadow: "0px 3px 10px",
  defaultTransition: "all 0.4s ease",
  fastTransition: "all 0.3s ease",
  font: "'Lato', sans-serif",
  grey0: "#f0f5f9",
  grey1: "#e1e8ee",
  grey2: "#bdc6cf",
  grey3: "#86939e",
  grey4: "#5e6977",
  grey5: "#43484d",
  grey6: "#393e42",
  greyA: "#202325",
  lightTint: "rgba(0,0,0,0.8)",
  lightestGrey: "hsla(0, 0%, 0%, 0.05)",
  midBlue: "#369bffff",
  midGreen: "#3CD55D",
  midPurple: "#8F5CFF",
  midRed: "#FC4E4E",
  midYellow: "#ffd26e",
  midYellowAccent: "#ffeabd",
  notSubtleBoxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
  pastelBlue: "#E6F2FF",
  pastelGreen: "#2e352f",
  pastelPurple: "#F4EEFF",
  pastelRed: "#FFEDED",
  pastelRedDarkMode: "#462E2E",
  pastelYellowDarkMode: "#202325",
  pastelYellow: "#FFF8EF",
  skeletonBackgroundColor: "rgba(35,35,35,0.2)",
  skeletonForegroundColor: "rgba(55,55,55,0.3)",
  slowTransition: "all 1.5s ease",
  smallBoxShadow: "0px 3px 5px",
  sortOfSubtleBoxShadow: "0px 3px 5px rgba(0,0,0,0.1)",
  subtleBoxShadow: "0px 3px 5px rgba(0,0,0,0.05)",
  subtleDarkTint: "rgba(255,255,255,0.1)",
  sunYellow: "#ffd26e",
  veryLightGrey: "#444444",
  white: "#000000",
};
