import {
  useNavigate,
  useParams,
  useMatch,
  useLocation,
} from "react-router-dom";

export function withRouter(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const match = useMatch("*");

    return (
      <Component
        {...props}
        navigate={navigate}
        location={location}
        params={params}
        match={match}
      />
    );
  };
}
