import "babel-polyfill";
import "isomorphic-fetch";
import { TOAST_ACTIONS, TOAST_TRIGGERS } from "constants/variables";
import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects";
import { hideLoading, showLoading } from "react-redux-loading-bar";
import { destroyLoginCookieAndLocalStorage } from "constants/functions";
import { store } from "@redux/store";
import toast from "react-hot-toast";
import { apiCall } from "shared/helpers/apiCall";

const showLoadingBar = [
    "FEED_PHOTOS",
    "ANALYTICS_PLANOGRAM_CHECK",
    "FACINGS_SEARCH",
    "PRODUCT_PRICING",
    "PRODUCT_PRICING_MORE",
    "SUBMIT_MEDIA_BRIEF",
    "PRIVATE_COLLECTION",
    "COLLECTIONS_EXPLORE",
    "COLLECTIONS_OWNED",
    "DIRECTORY_COMPANIES",
    "DIRECTORY_COUNTRIES",
    "PLANOGRAM_STITCH_IMAGES",
];


function* logoutSaga(payload) {
    destroyLoginCookieAndLocalStorage();
    yield put({ type: "LOGOUT" });
}

// worker saga: makes the api call when watcher saga sees the action
function* workerSaga({ payload }) {
    const { component, params, method, route } = payload;
    const needsLoadingBar = showLoadingBar.includes(component) || component.includes("ANALYTICS_REPORT_GET");

    if (needsLoadingBar) yield put(showLoading());
    try {
        const data = yield call(apiCall, payload);
        // dispatch a success action to the store with the new data
        yield put({ type: "API_CALL_SUCCESS", payload });
        if (data) {
            if (typeof data?.error === 'string' && data.error.includes("auth_failure")) {
                destroyLoginCookieAndLocalStorage();
                yield put({ type: "LOGOUT" });
            } else {
                if (component === "ADMIN_IMPERSONATE_USER") {
                    yield put({ type: "LOGOUT" });
                    yield delay(500)
                }
                yield put({ type: `${component}_DATA`, data });
            }
        }
    } catch (error) {
        const { status, message } = error;
        yield put({ type: "API_CALL_FAILURE", error: { status, message }, payload });

        if (method === "GET" || component === "FEED_PHOTOS") {
            yield put({ type: "SHOW_OFFLINE" });
            yield put({ type: "LAST_API_CALL", payload: { component, route, method, params }});
        }
    } finally {
        if (needsLoadingBar) yield put(hideLoading());
    }
}

function* toastSaga(payload) {
    toast.dismiss();
    const toastInfo = TOAST_ACTIONS[payload.type] || { success: true, message: "Saved" };
    const toastStyle = store.getState().dataUser?.dark_mode
        ? {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
        }
        : {
            borderRadius: "10px",
            background: "#FFF",
            color: "#333",
        };
    toast.success(toastInfo.message, {
        duration: 1500,
        style: toastStyle,
    });
}

function* fetchImagesSaga({ payload }) {
    yield put(showLoading());
    try {
        const blob = yield call(apiCall, { ...payload, responseType: "blob" });
        saveAs(blob, `${payload.body.filename}.jpeg`);
        yield put({ type: "API_CALL_SUCCESS", payload });
    } catch (error) {
        yield put({ type: "API_CALL_FAILURE", error, payload });
    } finally {
        yield put(hideLoading());
    }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* watcherSaga() {
    yield takeEvery("WEBSOCKETS/log_user_out", logoutSaga);
    yield takeEvery("API_CALL_REQUEST", workerSaga);
    yield takeLatest("API_CALL_TAKE_LATEST", workerSaga);
    yield takeLatest(TOAST_TRIGGERS, toastSaga);
    yield takeLatest("PLANOGRAM_STITCH_IMAGES", fetchImagesSaga);
}

