// Type Guards
export var isCuratedCollection = function (collection) {
    return collection.is_curated;
};
export var isRetailPipeline = function (collection) {
    return collection.is_pipeline;
};
export var isCustomCollection = function (collection) {
    return !!collection.custom_type_name;
};
