import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FaSnowflake, FaSun, FaCloudRain, FaCalendarPlus, FaCloudSun, FaSlidersH, FaRegCalendarAlt, FaSortAmountDown, FaSortAmountUpAlt, FaRegClock, FaHistory, } from "react-icons/fa";
import { Option } from "constants/styled-components/index";
export var emptyArray = [];
export var emptyObject = {};
export var PHOTO_FEED_LIMIT_FREE_PLAN = 80;
export var PHOTO_FEED_LIMIT = 240;
export var MAX_POG = 15;
export var tooltipDelay = 450;
export var tooltipDelayLong = 800;
export var maxUploadSize = 7340032;
export var learningCenterUrl = "https://docs.shelfgram.com/";
export var clearbitUrl = "https://logo.clearbit.com/";
export var minFlyerDate = new Date("2012-02-29");
export var minPhotosDate = new Date("2005-01-01");
export var genericErrorMessage = "Sorry, we couldn't find anything matching your filters";
export var isMobileDevice = window.matchMedia("(max-width: 420px)").matches;
export var MIN_FEED_CARD_SIZE = 270;
export var copyright = "\u00A9 2020 - ".concat(new Date().getFullYear(), ", Shelfgram Inc.");
export var appleAppUrl = "https://apps.apple.com/us/app/shelfgram/id1489053182?ls=1";
export var androidAppUrl = "https://play.google.com/store/apps/details?id=com.shelfgram.app&hl=en";
export var isDev = process.env.NODE_ENV == "development";
export var loginCookieName = "shelfgram_token";
export var adminPlanId = 5;
export var qcPlanId = 4;
export var validPlanIdsForDelete = [adminPlanId, qcPlanId];
export var API = isDev
    ? "http://localhost:8090/api/"
    : window.location.host === "dev.shelfgram.com"
        ? "https://api-dev.shelfgram.com/api/"
        : "https://api-prod.shelfgram.com/api/";
export var demoLink = "https://demo.shelfgram.com";
export var newFeaturesRSS = "product-updates.json";
export var companyWebsite = "https://shelfgram.com/";
export var companyWebsiteNoSlash = "https://shelfgram.com";
export var appPrefixLocal = isDev
    ? "http://localhost:3000/"
    : "https://app.shelfgram.com/";
export var appPrefix = "https://app.shelfgram.com/";
export var awsPrefix = "https://cdn.shelfgram.com/";
export var awsPrefixS3 = "https://s3.amazonaws.com/retail-photos/";
export var awsPhotosPrefix = "https://photos.shelfgram.com/";
export var awsPhotosPrefixS3 = "https://s3.amazonaws.com/shelfgram-photos/";
export var wikipediaPrefix = "https://en.wikipedia.org/wiki/";
export var worldPopulation = 7127361454;
export var worldGDP = 117003980189996;
export var clubChannelSlugs = ["c1N21iMY", "RmRU9gV", "iXcbyaH"];
export var breakpointColumnsObj = {
    default: 5,
    1100: 4,
    700: 2,
    500: 1,
};
export var colorPaletteLite = [
    "rgb(142,209,252)",
    "rgb(0,77,207)",
    "rgb(153,0,239)",
    "rgb(183,28,51)",
    "rgb(245,124,0)",
    "rgb(255,210,7)",
    "rgb(247,141,167)",
    "rgb(0,139,2)",
    "rgb(121,85,51)",
    "rgb(27,27,27)",
    "rgb(151,151,151)",
    "rgb(250,250,250)",
];
export var colorPalette = [
    "#ffb6c1",
    "#ff80ed",
    "#e91e63",
    "#B71C1C",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#407294",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#fed766",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#666666",
    "#607d8b",
    "#EEEEEE",
    "#e6e6fa",
    "#333333",
    "#111111",
];
export var presentationTypeToLayout = {
    photos: {
        masterName: "MASTER_SLIDE_WITH_NOTES",
    },
    cards: {
        cards: 3,
        size: 1,
        yOffset: 0,
        masterName: "MASTER_SLIDE_CARDS",
    },
    grid: {
        cards: 10,
        size: 0.6,
        yOffset: -0.4,
        masterName: "MASTER_SLIDE_GRID",
    },
    title: {
        cards: 4,
        size: 0.75,
        yOffset: 0.8,
        masterName: "MASTER_SLIDE_WITH_TITLE",
    },
};
export var TOAST_TRIGGERS = [
    "ALERT_CREATE_DATA",
    "ALERT_DELETE_DATA",
    "ANALYTICS_REPORT_INCLUDE_API_DATA",
    "ANALYTICS_REPORT_EXCLUDE_API_DATA",
    "PUBLICATIONS_SET_SORT_SAVE_DATA",
    "USER_SAVE_FONT_SIZE_DATA",
    "USER_SAVE_CONTRAST_DATA",
    "USER_SET_PPT_LOGO_PREFERENCE_DATA",
    "USER_SET_PPT_COMPRESSION_PREFERENCE_DATA",
    "USER_SELECT_LANGUAGE_SAVE_DATA",
    "COLLECTION_TOGGLE_CURATION_DATA",
    "COLLECTION_CHANGE_COUNTRY_DATA",
    "COLLECTION_UPLOAD_COVER_DATA",
    "AUTOMATION_RULES_SAVE_TO_API_DATA",
    "COLLECTION_CHANGE_COLORS_DATA",
    "COLLECTION_CHANGE_COVER_DATA",
    "COLLECTION_CHANGE_NAME_DATA",
    "COLLECTION_CHANGE_SUMMARY_DATA",
    "COLLECTION_CREATE_DATA",
    "COLLECTIONS_LINK_PHOTO_DATA",
    "COLLECTIONS_UNLINK_PHOTO_DATA",
    "COMPANY_CATEGORIES_EDIT_DATA",
    "COMPANY_PARENTS_EDIT_DATA",
    "COMPANY_CHANNELS_EDIT_DATA",
    "CREATE_A_COMPANY_DATA",
    "DELETE_DATA",
    "FEED_SET_DEFAULT_SORT_DATA",
    "HAWKEYE_DELETE_DATA",
    "HAWKEYE_APPROVE_DATA",
    "LOCATION_STORE_NUMBER_EDIT_DATA",
    "PUBLICATIONS_SET_SORT_SAVE",
    "PRIVATE_COLLECTION_CHANGE_COLORS_DATA",
    "PRIVATE_COLLECTION_CHANGE_COVER_DATA",
    "PRIVATE_COLLECTION_CHANGE_NAME_DATA",
    "PRIVATE_COLLECTION_CHANGE_PREVIEW_DATA",
    "PRIVATE_COLLECTION_CHANGE_SUMMARY_DATA",
    "PRIVATE_COLLECTION_CHANGE_DOWNGRADE_DATA",
    "REPORT_DATA",
    "SAVE_INCLUDED_CATEGORIES_DATA",
    "SAVE_NEW_PROFILE_PIC_DATA",
    "TOGGLE_USER_PRIVATE_COLLECTION_ADMIN_OFF_DATA",
    "TOGGLE_USER_PRIVATE_COLLECTION_ADMIN_ON_DATA",
    "USER_CHANGE_NAME_DATA",
    "USER_SAVE_CARD_PREFS_DATA",
    "USER_SELECT_HOMEPAGE_PREFERENCE_SAVE_DATA",
    "USER_SET_LAYOUT_PREFERENCE_DATA",
    "USER_TOGGLES_PUBLISHER_STATUS_DATA",
    "WHITELIST_EMAIL_DOMAIN_DATA",
];
export var TOAST_ACTIONS = {
    COLLECTIONS_LINK_PHOTO_DATA: {
        success: true,
        message: "Added to collections",
    },
    TOGGLE_USER_PRIVATE_COLLECTION_ADMIN_OFF_DATA: {
        success: true,
        message: "Updated",
    },
    TOGGLE_USER_PRIVATE_COLLECTION_ADMIN_ON_DATA: {
        success: true,
        message: "Updated",
    },
    COLLECTIONS_UNLINK_PHOTO_DATA: { success: true, message: "Removed" },
    ALERT_CREATE_DATA: { success: true, message: "Alert created" },
    ALERT_DELETE_DATA: { success: true, message: "Alert deleted" },
    COLLECTION_CREATE_DATA: { success: true, message: "Collection created" },
    DELETE_DATA: { success: true, message: "Deleted" },
    REPORT_DATA: { success: true, message: "Photo reported" },
    USER_CHANGE_NAME_DATA: { success: true, message: "Profile saved" },
};
export var userMenu = [
    { slug: "private_spaces", trans: "130", gated: true },
    { slug: "upload_photos", trans: "76", gated: true },
    { slug: "settings", trans: "5" },
];
export var directoryMenu = [
    {
        name: "Channels",
        slug: "/directory/channels",
        trans: "27",
        route: "channel",
        source: "channels",
        page: "channels",
        largeSize: true,
        paginated: false,
    },
    {
        name: "Categories",
        slug: "/directory/categories",
        trans: "42",
        route: "category",
        source: "categories",
        page: "categories",
        largeSize: true,
        paginated: false,
    },
    {
        name: "Retailers",
        slug: "/directory/retailers",
        trans: "73",
        route: "company",
        source: "companies",
        page: "retailers",
        largeSize: false,
        paginated: true,
    },
    {
        name: "Brands",
        slug: "/directory/brands",
        trans: "41",
        route: "company",
        source: "companies",
        page: "brands",
        largeSize: false,
        paginated: true,
    },
    {
        name: "Tags",
        slug: "/directory/tags",
        trans: "43",
        route: "tag",
        source: "tags",
        page: "tags",
        largeSize: true,
        paginated: false,
    },
    {
        name: "Countries",
        slug: "/directory/countries",
        trans: "71",
        route: "country",
        source: "countries",
        page: "countries",
        largeSize: false,
        paginated: false,
    },
    {
        name: "Regions",
        slug: "/directory/regions",
        trans: "72",
        route: "region",
        source: "regions",
        page: "regions",
        largeSize: true,
        paginated: false,
    },
];
export var pluralToSingular = {
    osa: { lower: "stock levels", upper: "Stock Levels" },
    question_id: { lower: "view", upper: "View" },
    map: { lower: "map", upper: "Map" },
    time_travel: { lower: "time travel", upper: "Time Travel" },
    postal_zip: { lower: "post code", upper: "Post Code" },
    text: { lower: "text", upper: "Text" },
    dates: { lower: "date", upper: "Date" },
    months: { lower: "month", upper: "Month" },
    temperature: { lower: "temperature", upper: "Temperature" },
    weather: { lower: "weather", upper: "Weather" },
    countries: { lower: "country", upper: "Country" },
    channels: { lower: "channel", upper: "Channel" },
    categories: { lower: "category", upper: "Category" },
    collections: { lower: "collection", upper: "Collection" },
    demographics: { lower: "demographic", upper: "Demography" },
    private_collections: {
        lower: "private space",
        upper: "Private Space",
    },
    users: { lower: "user", upper: "User" },
    uploads: { lower: "upload", upper: "Uploads" },
    companies: { lower: "company", upper: "Company" },
    brands: { lower: "brand", upper: "Brand" },
    products: { lower: "product", upper: "Product" },
    retailers: { lower: "retailer", upper: "Retailer" },
    tags: { lower: "tag", upper: "Tag" },
    regions: { lower: "region", upper: "Region" },
    locations: { lower: "location", upper: "Location" },
    cities: { lower: "city", upper: "City" },
    states: { lower: "state", upper: "State" },
};
export var SUGGESTION_WHITELIST = [
    "channels",
    "categories",
    "retailers",
    "brands",
    "collections",
    "countries",
    "states",
    "cities",
];
export var MATCH_OPTIONS = [
    {
        group: "Popular",
        options: [
            {
                value: "retailer",
                label: "Retailer",
                plural: "retailers",
                suggest: true,
            },
            {
                value: "brand",
                label: "Brand",
                plural: "brands",
                suggest: true,
            },
            {
                value: "text",
                label: "Text",
                plural: "text",
                suggest: false,
                text: true,
            },
            {
                value: "category",
                label: "Category",
                plural: "categories",
                suggest: true,
            },
            {
                value: "tag",
                label: "Tag",
                plural: "tags",
                suggest: false,
            },
            {
                value: "date",
                label: "Date",
                plural: "dates",
                suggest: false,
            },
        ],
    },
    {
        group: "Geography",
        options: [
            {
                value: "region",
                label: "Region",
                plural: "regions",
                suggest: false,
            },
            {
                value: "country",
                label: "Country",
                plural: "countries",
                suggest: true,
            },
            {
                value: "state",
                label: "State",
                plural: "states",
                suggest: true,
            },
            {
                value: "city",
                label: "City",
                plural: "cities",
                suggest: true,
            },
            {
                value: "demographic",
                label: "Demography",
                plural: "demographics",
                suggest: false,
            },
            {
                value: "postal_zip",
                label: "Post Code",
                plural: "postal_zip",
                suggest: false,
                text: true,
            },
            {
                value: "map",
                label: "Map",
                plural: "map",
                suggest: false,
            },
        ],
    },
    {
        group: "Other",
        options: [
            {
                value: "user",
                label: "User",
                plural: "users",
                suggest: false,
            },
            {
                value: "months",
                label: "Month",
                plural: "months",
                suggest: false,
            },
            {
                value: "location",
                label: "Location",
                plural: "locations",
                suggest: false,
            },
            {
                value: "channel",
                label: "Channel",
                plural: "channels",
                suggest: true,
            },
            {
                value: "time_travel",
                label: "Time Travel",
                plural: "time_travel",
                suggest: false,
            },
            {
                value: "collection",
                label: "Collection",
                plural: "collections",
                suggest: true,
            },
        ],
    },
];
export var monthsOptions = [
    {
        id: 1,
        name: "January",
        value: "January",
        label: "January",
    },
    {
        id: 2,
        name: "February",
        value: "February",
        label: "February",
    },
    {
        id: 3,
        name: "March",
        value: "March",
        label: "March",
    },
    {
        id: 4,
        name: "April",
        value: "April",
        label: "April",
    },
    {
        id: 5,
        name: "May",
        value: "May",
        label: "May",
    },
    {
        id: 6,
        name: "June",
        value: "June",
        label: "June",
    },
    {
        id: 7,
        name: "July",
        value: "July",
        label: "July",
    },
    {
        id: 8,
        name: "August",
        value: "August",
        label: "August",
    },
    {
        id: 9,
        name: "September",
        value: "September",
        label: "September",
    },
    {
        id: 10,
        name: "October",
        value: "October",
        label: "October",
    },
    {
        id: 11,
        name: "November",
        value: "November",
        label: "November",
    },
    {
        id: 12,
        name: "December",
        value: "December",
        label: "December",
    },
];
export var POWERPOINT_CONSTANTS = {
    CARD_SPACING: 0.3,
    GLOBAL_FONT: "Helvetica",
    CARD_WIDTH_FACTOR: 3.2,
};
export var pptxFooterObjects = [
    {
        rect: {
            x: -0.01,
            y: 5.255,
            w: 10,
            h: 0.37,
            fill: "5E6977",
        },
    },
    {
        rect: {
            x: 8,
            y: 5.255,
            w: 2.01,
            h: 0.37,
            fill: "FFFFFF",
        },
    },
    {
        rect: {
            x: 8.6,
            y: 4.9,
            w: 0.6,
            h: 0.6,
            rotate: 45,
            fill: "E1E8EE",
        },
    },
    {
        rect: {
            x: 8.6,
            y: 5.255,
            w: 0.3,
            h: 0.37,
            fill: "E1E8EE",
        },
    },
    {
        rect: {
            x: 8.3,
            y: 4.9,
            w: 0.6,
            h: 0.6,
            rotate: 45,
            fill: "BDC6CF",
        },
    },
    {
        rect: {
            x: 8.3,
            y: 5.255,
            w: 0.3,
            h: 0.37,
            fill: "BDC6CF",
        },
    },
    {
        rect: {
            x: 8,
            y: 4.9,
            w: 0.6,
            h: 0.6,
            rotate: 45,
            fill: "86939E",
        },
    },
    {
        rect: {
            x: 8,
            y: 5.255,
            w: 0.3,
            h: 0.37,
            fill: "86939E",
        },
    },
    {
        rect: {
            x: 7.7,
            y: 4.9,
            w: 0.6,
            h: 0.6,
            rotate: 45,
            fill: "5E6977",
        },
    },
    {
        rect: {
            x: 0,
            y: 0,
            w: "100%",
            h: 5.255,
            fill: "F0F5F9",
        },
    },
];
export var matchMinWidthMap = {
    osa: "14em",
    demographics: "14em",
    postal_zip: "14em",
    private_collections: "15em",
    temperature: "13em",
};
export var pageToKey = {
    tag: "tags",
    text: "text",
    related: "related",
    demography: "demographics",
    category: "categories",
    channel: "channels",
    country: "countries",
    product: "products",
    company: "companies",
    location: "locations",
    city: "cities",
    state: "states",
    region: "regions",
    collection: "collections",
    upload: "uploads",
    private_collection: "private_collections",
};
export var asyncSelectors = [
    "cities",
    "locations",
    "companies",
    "users",
    "brands",
    "retailers",
    "states",
    "private_collections",
];
export var useCompaniesS3Key = [
    "companies",
    "brands",
    "retailers",
    "private_collections",
    "locations",
];
export var numToLetterMap = {
    0: "a",
    1: "b",
    2: "c",
    3: "d",
    4: "e",
    5: "f",
    6: "g",
    7: "h",
    8: "i",
    9: "j",
};
export var lettersToNumberMap = {
    a: "0",
    b: "1",
    c: "2",
    d: "3",
    e: "4",
    f: "5",
    g: "6",
    h: "7",
    i: "8",
    j: "9",
};
export var mainSectionTag = {
    name: "Main Section",
    img: "82ad31b1-8416-50a4-844b-16e01e6c5156",
    id: "V5ikpTzI",
    url_slug: "V5ikpTzI",
};
export var photoTagTypes = [
    mainSectionTag,
    {
        name: "Displays",
        img: "5f2ed5d5-7370-556d-9697-77b0bc90dad7",
        id: "U_9aCZEN",
    },
];
export var partnerCountries = [
    {
        name: "Canada",
        img: "25635ee9-5114-5c34-9f27-a09312d7fdd3",
        source: "countries",
        url_slug: "vLlt3ct",
        id: 5,
    },
    {
        name: "United States",
        img: "7ce02049-3129-5dae-9a78-e27228611ee5",
        source: "countries",
        url_slug: "bkO6Cjp",
        id: 390,
    },
    {
        name: "Australia",
        img: "3a1735a8-d822-5482-828e-1bc2031a10a0",
        source: "countries",
        url_slug: "BAHnrPI",
        id: 22,
    },
    {
        name: "South Africa",
        img: "4c12d0e7-4deb-55af-8413-3b80a83db219",
        source: "countries",
        url_slug: "ChwGRWL",
        id: 347,
    },
    {
        name: "United Kingdom",
        img: "aaab44bf-1c8f-50bc-a51c-9ef71361abc6",
        source: "countries",
        url_slug: "Gp9NVp5",
        id: 377,
    },
    {
        name: "Mexico",
        img: "88a352a3-f63d-5e6c-873f-cdf11bdbfc9c",
        source: "countries",
        url_slug: "ch8VCf2",
        id: 283,
    },
    {
        name: "Spain",
        img: "d85c1a5d-33cf-559c-8340-ac4089868694",
        source: "countries",
        url_slug: "ZAYLieE",
        id: 351,
    },
    {
        name: "Ecuador",
        img: "0ce88dba-dee0-57b5-8601-082b53026e04",
        source: "countries",
        url_slug: "6VXgtUN",
        id: 104,
    },
];
export var partnerCountryMapping = partnerCountries.reduce(function (acc, country) {
    acc[country.id] = country;
    return acc;
}, {});
export var selectors = [
    {
        name: "countries",
        route: "country",
        async: false,
    },
    {
        name: "channels",
        route: "channel",
        async: false,
    },
    {
        name: "categories",
        route: "category",
        async: false,
    },
    {
        name: "brands",
        route: "company",
        async: true,
    },
    {
        name: "retailers",
        route: "company",
        async: true,
    },
    {
        name: "locations",
        route: "location",
        async: true,
    },
    {
        name: "tags",
        route: "tag",
        async: false,
    },
];
export var dateOptions = [
    {
        label: "Custom dates",
        options: [
            {
                value: "Custom",
                name: "Custom",
                id: "custom",
                noImg: true,
                type: "dates",
                label: (_jsxs(Option, { children: [_jsx(FaRegCalendarAlt, {}), "Custom"] })),
            },
        ],
    },
    {
        label: "Preset options",
        options: [
            {
                value: "Today",
                name: "Today",
                id: "today",
                noImg: true,
                type: "dates",
                label: _jsx(Option, { children: "Today" }),
            },
            {
                value: "Yesterday",
                name: "Yesterday",
                noImg: true,
                id: "yesterday",
                type: "dates",
                label: _jsx(Option, { children: "Yesterday" }),
            },
            {
                value: "Last 7 days",
                name: "Last 7 days",
                noImg: true,
                id: "l7",
                type: "dates",
                label: _jsx(Option, { children: "Last 7 days" }),
            },
            {
                value: "Last 30 days",
                name: "Last 30 days",
                noImg: true,
                id: "l30",
                type: "dates",
                label: _jsx(Option, { children: "Last 30 days" }),
            },
            {
                value: "Last 90 days",
                name: "Last 90 days",
                noImg: true,
                id: "l90",
                type: "dates",
                label: _jsx(Option, { children: "Last 90 days" }),
            },
            {
                value: "Last 365 days",
                name: "Last 365 days",
                noImg: true,
                id: "l365",
                type: "dates",
                label: _jsx(Option, { children: "Last 365 days" }),
            },
            {
                value: "Previous 30 days",
                name: "Previous 30 days",
                noImg: true,
                id: "p30",
                type: "dates",
                label: _jsx(Option, { children: "Previous 30 days" }),
            },
            {
                value: "Previous 90 days",
                name: "Previous 90 days",
                noImg: true,
                id: "p90",
                type: "dates",
                label: _jsx(Option, { children: "Previous 90 days" }),
            },
            {
                value: "Previous 365 days",
                name: "Previous 365 days",
                noImg: true,
                id: "p365",
                type: "dates",
                label: _jsx(Option, { children: "Previous 365 days" }),
            },
            {
                value: "Current month",
                name: "Current month",
                noImg: true,
                id: "currentMonth",
                type: "dates",
                label: _jsx(Option, { children: "Current month" }),
            },
            {
                value: "Last month",
                name: "Last month",
                noImg: true,
                id: "lastMonth",
                type: "dates",
                label: _jsx(Option, { children: "Last month" }),
            },
            {
                value: "Year to date",
                name: "Year to date",
                noImg: true,
                id: "ytd",
                type: "dates",
                label: _jsx(Option, { children: "Year to date" }),
            },
        ],
    },
];
export var alertFrequencyIndexMap = {
    2: 0,
    3: 1,
    4: 2,
};
export var sortOptions = {
    relevant: {
        name: "Most revelant",
        tooltip: "This only applies a personalization algorithm to the main photo feed and uses Newest everywhere else.",
        displayName: "Sort: relevant first",
        icon: _jsx(FaSlidersH, {}),
    },
    newest: {
        name: "Newest first",
        tooltip: "This sorts photos by the date they were taken.",
        displayName: "Sort: newest first",
        icon: _jsx(FaSortAmountDown, {}),
        planogram: true,
        flyer: true,
    },
    oldest: {
        name: "Oldest first",
        tooltip: "This sorts photos by the date they were taken.",
        displayName: "Sort: oldest first",
        icon: _jsx(FaSortAmountUpAlt, {}),
        planogram: true,
        flyer: true,
    },
    date_added: {
        name: "Date added",
        tooltip: "This sorts photos by the date they were uploaded to Shelfgram.",
        displayName: "Sort: date added",
        icon: _jsx(FaCalendarPlus, {}),
    },
};
export var whitelistedSorts = Object.keys(sortOptions);
export var commonEmojiMap = [
    {
        emoji: "🤔",
        name: "thinking",
        color: "rgba(240,170,209,0.3)",
    },
    {
        emoji: "😊",
        name: "smile",
        color: "rgba(170,200,240,0.3)",
    },
    {
        emoji: "😃",
        name: "happy",
        shortcut: ":)",
        color: "rgba(170,240,209,0.3)",
    },
    {
        emoji: "😍",
        name: "love",
        color: "rgba(100,240,109,0.3)",
    },
    {
        emoji: "😲",
        name: "surprised",
        shortcut: ":O",
        color: "rgba(170,240,209,0.3)",
    },
    {
        emoji: "😛",
        name: "tongue",
        shortcut: ":P",
        color: "rgba(250,240,9,0.3)",
    },
    {
        emoji: "😂",
        name: "crying",
        shortcut: ":-)",
        color: "rgba(200,100,209,0.3)",
    },
    {
        emoji: "😉",
        name: "wink",
        shortcut: ";)",
        color: "rgba(250,40,90,0.3)",
    },
    {
        emoji: "😎",
        name: "cool",
        color: "rgba(170,0,209,0.3)",
    },
    {
        emoji: "😞",
        name: "sad",
        shortcut: ":(",
        color: "rgba(170,240,0,0.3)",
    },
    {
        emoji: "🙏",
        name: "praise",
        color: "rgba(0,240,209,0.3)",
    },
    {
        emoji: "👌",
        name: "ok",
        color: "rgba(70,40,209,0.3)",
    },
    {
        emoji: "🙌",
        name: "hands",
        color: "rgba(17,240,29,0.3)",
    },
    {
        emoji: "👏",
        name: "clap",
        color: "rgba(10,24,20,0.3)",
    },
    {
        emoji: "👍",
        name: "thumbsup",
        color: "rgba(50,200,209,0.3)",
    },
    {
        emoji: "👎",
        name: "thumbsdown",
        color: "rgba(170,240,100,0.3)",
    },
    {
        emoji: "❤️",
        name: "heart",
        shortcut: "<3",
        color: "rgba(170,200,200,0.3)",
    },
    {
        emoji: "🔥",
        name: "fire",
        color: "rgba(250,100,100,0.3)",
    },
    {
        emoji: "🎉",
        name: "party",
        color: "rgba(170,240,109,0.3)",
    },
    {
        emoji: "💯",
        name: "100",
        color: "rgba(170,140,209,0.3)",
    },
    {
        emoji: "✅",
        name: "check",
        color: "rgba(100,200,209,0.3)",
    },
    {
        emoji: "🏆",
        name: "trophy",
        color: "rgba(250,250,100,0.3)",
    },
    {
        emoji: "💡",
        name: "idea",
        color: "rgba(170,140,159,0.3)",
    },
    {
        emoji: "🛒",
        name: "cart",
        color: "rgba(200,240,259,0.3)",
    },
];
export var styleSmallFont = { fontSize: "0.8em" };
export var styleFlexCenter = { display: "flex", alignItems: "center" };
export var styleFlex = { display: "flex" };
export var tooltipFull = { width: "100%", display: "flex" };
export var fullWidth = { width: "100%" };
export var maxSizeStyle = {
    height: "100%",
    width: "100%",
};
export var inputStyle = {
    width: "100%",
    height: "2.25em",
    padding: "0 1em",
    boxSizing: "border-box",
    border: "none",
    borderRadius: "2em",
};
export var weatherIcons = {
    snow: _jsx(FaSnowflake, {}),
    rain: _jsx(FaCloudRain, {}),
    "clear-day": _jsx(FaSun, {}),
    cloudy: _jsx(FaCloudSun, {}),
};
export var isOptionAdmin = {
    value: "is none",
    noImg: true,
    disabled: false,
    label: "is none",
    id: "is_none",
};
export var isOptions = [
    {
        value: "is",
        label: "is",
        noImg: true,
        disabled: false,
        id: "is",
    },
    {
        value: "is not",
        noImg: true,
        disabled: false,
        label: "is not",
        id: "is_not",
    },
];
export var isOptionsNumeric = [
    {
        value: "is",
        label: "are >",
        noImg: true,
        disabled: false,
        id: "is",
    },
    {
        value: "is not",
        label: "are <",
        noImg: true,
        disabled: false,
        id: "is_not",
    },
];
export var timeTravelOptions = [
    {
        value: "enabled",
        label: (_jsxs(Option, { children: [_jsx(FaHistory, {}), "Available"] })),
        name: "Available",
        noImg: true,
        id: "enabled",
    },
    {
        value: "disabled",
        label: (_jsxs(Option, { children: [_jsx(FaRegClock, {}), "Not yet available"] })),
        name: "Not yet available",
        noImg: true,
        id: "disabled",
    },
];
export var osaOptions = [
    {
        value: 50,
        label: "50%",
        noImg: true,
        disabled: false,
        id: 50,
    },
    {
        value: 55,
        label: "55%",
        noImg: true,
        disabled: false,
        id: 55,
    },
    {
        value: 60,
        label: "60%",
        noImg: true,
        disabled: false,
        id: 60,
    },
    {
        value: 65,
        label: "65%",
        noImg: true,
        disabled: false,
        id: 65,
    },
    {
        value: 70,
        label: "70%",
        noImg: true,
        disabled: false,
        id: 70,
    },
    {
        value: 75,
        label: "75%",
        noImg: true,
        disabled: false,
        id: 75,
    },
    {
        value: 80,
        label: "80%",
        noImg: true,
        disabled: false,
        id: 80,
    },
    {
        value: 85,
        label: "85%",
        noImg: true,
        disabled: false,
        id: 85,
    },
    {
        value: 90,
        label: "90%",
        noImg: true,
        disabled: false,
        id: 90,
    },
    {
        value: 95,
        label: "95%",
        noImg: true,
        disabled: false,
        id: 95,
    },
];
export var dropdownDesktopStyle = {
    marginRight: "0",
    marginLeft: "0.5em",
    height: "1.75em",
    width: "1.75em",
};
export var socialProofLogos = [
    "unilever",
    "ecobeelogo",
    "scotts",
    "jnj",
    "kdrp",
    "np",
    "pharmascience",
    "nestle",
    "smuckers",
    "loreal",
    "righteous",
    "church",
];
export var tools = [
    {
        name: "Photos",
        slug: "/feed",
        img: "photos.png",
        summary: "See what your shoppers are seeing",
    },
    {
        name: "Circulars",
        slug: "/circulars",
        img: "flyers.png",
        summary: "Weekly flyer tracking",
    },
    {
        name: "Planograms",
        enterprise: "enterprise-planograms.png",
        slug_free: "/enterprise/planogram_features",
        slug: "/planograms",
        img: "planograms.png",
        summary: "Walk the entire aisle virtually",
    },
    {
        name: "Price checks",
        enterprise: "enterprise-price-checks.png",
        slug_free: "/enterprise/pricing_features",
        slug: "/price_checks",
        img: "price-checks.png",
        summary: "Fully automated retail price checks",
    },
    {
        name: "Analytics",
        enterprise: "enterprise-analytics.png",
        slug_free: "/enterprise/analytics_features",
        slug: "/analytics",
        img: "analytics.png",
        summary: "Summary retail execution reports",
    },
    {
        name: "Campaigns",
        enterprise: "enterprise-campaigns.png",
        slug_free: "/enterprise/features_campaigns",
        slug: "/campaigns",
        img: "campaigns.png",
        summary: "Omnichannel promos",
    },
];
