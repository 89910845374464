import { PureComponent } from "react";

import { withRouter } from "services/with-router/index";

class ScrollToTop extends PureComponent {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
      const scroller1 = document.getElementById("scroller-enabled");
      if (scroller1) {
        scroller1.scrollTo(0, 0);
      } else {
        const scroller2 = document.getElementById("scroller-disabled");
        if (scroller2) {
          scroller2.scrollTo(0, 0);
        }
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
