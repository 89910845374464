import { createSlice } from "@reduxjs/toolkit";
import { languageEs } from "constants/translations/es";
import { languageEn } from "constants/translations/en";
import { languageFr } from "constants/translations/fr";
import { languageCh } from "constants/translations/ch";
import { languageGe } from "constants/translations/ge";
import { languagePo } from "constants/translations/po";
import { logout } from "@redux/actions/logout";
var translations = {
    ch: languageCh,
    es: languageEs,
    en: languageEn,
    fr: languageFr,
    ge: languageGe,
    po: languagePo
};
var initialState = languageEn;
var translationsSlice = createSlice({
    name: "translations",
    initialState: initialState,
    reducers: {
        setLanguage: function (state, action) {
            return translations[action.payload] || state;
        }
    },
    extraReducers: function (builder) {
        builder.addCase(logout, function () { return translations.en; });
    }
});
export default translationsSlice.reducer;
export var setLanguage = translationsSlice.actions.setLanguage;
