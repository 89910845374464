import { media } from "constants/responsive/index";
import styled from "styled-components";
import { textColor } from "constants/functions/index";

export const Padding = styled.div`
  padding: ${(props) => (props.amount ? props.amount : "1em")};
  width: 100%;
  box-sizing: border-box;
  ${media.mobile`
    padding: 1em;
  `};
`;
export const Group = styled.span`
  display: flex;
  min-width: 25%;
  justify-content: center;
  align-items: center;
  ${media.mobile`
    width: 100%;
    min-width: auto;
    justify-content: space-evenly;
    padding: 0.5em;
    .menuOptionSpacer {
      margin: 0 0.25em;
    }
  `};
`;
export const TextButton = styled.div`
  padding: 0.5em 0.75em;
  z-ndex: 1;
  background: ${(props) => props.theme.white};
  display: flex;
  margin: 0.25em;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.grey5};
  border-radius: 0.25em;
  transition: ${(props) => props.theme.fastTransition};
  align-items: center;
  color: ${(props) => props.theme.grey2};
  svg {
    font-size: 0.8em;
    margin-right: ${(props) => (props.noSvgMargin ? "0" : "0.5em")};
  }
  &:hover {
    border-color: ${(props) => props.theme.grey4};
    color: ${(props) => props.theme.grey0};
  }
}
`;
export const Spacer = styled.div`
  padding: 12em;
`;
export const TopBarWrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${(props) => (props.padding ? props.padding : "unset")};
  background: ${(props) => props.theme.white};
  border-bottom: 1px solid ${(props) => props.theme.grey5};
  z-index: 1;
  box-sizing: border-box;
  height: 3.25em;
  z-index: 3;
  overflow: hidden;
  ${media.mobile`
    padding: ${(props) =>
      props.paddingMobile ? props.paddingMobile : "unset"};
  `};
`;
export const ViewerDivider = styled.div`
  width: 100%;
  margin: 0 0.5em;
  border-color: ${(props) => props.theme.grey5};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #e1e8ee;
  padding-top: 0.5em;
`;
export const StyledSelect = styled.select`
  padding: 0.75em 1em;
  border-radius: 0.5em;
  border: none;
  width: 100%;
  appearance: none;
  cursor: pointer;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0;
  margin-right: 0.25em;
  color: ${(props) => props.theme.grey2};
  background: ${(props) => props.theme.white};
`;
export const SkeletonContainer = styled.div`
  padding: 0.5em;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`;
export const BottomP = styled.p`
  padding-bottom: 10em;
  color: ${(props) => props.theme.grey4};
  text-align: center;
`;
export const RedCircle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.deepBlue};
  border-radius: 100%;
  min-height: 2em;
  min-width: 2em;
  margin-right: 0.75em;
  color: white;
`;
export const RuleContainer = styled.div`
  animation: fadeIn 1s;
  width: 100%;
  display: flex;
  background: ${(props) => props.theme.grey6};
  justify-content: ${(props) => (props.transparent ? "flex-start" : "center")};
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  transition: ${(props) => props.theme.defaultTransition};
  border: ${(props) =>
    props.outline ? `1px solid ${props.theme.grey5}` : "none"};
  box-shadow: ${(props) =>
    props.outline ? props.theme.subtleBoxShadow : "none"};
  border-radius: 0.5em;
  ${media.tablet`
    font-size: 0.8em;
  `};
`;
export const PaddedFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  width: 100%;
`;
export const SpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const DividerLogo = styled.div`
  border-right: 1px solid ${(props) => props.theme.grey5};
  margin: 0 0.75em;
  height: 1.5em;
`;
export const DiscoverContainer = styled.div`
  width: 100%;
  animation: fadeIn 0.5s;
  padding: 0 1em 2em 1em;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
`;
export const FlyerSubMenu = styled.div`
  padding: 1em;
  width: 100%;
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
  display: flex;
  font-size: 0.8em;
  background: ${(props) => props.theme.grey1};
  align-items: center;
  justify-content: space-between;
`;
export const CardSpacing = styled.div`
  padding: 1%;
  box-sizing: border-box;
  display: flex;
  position: relative;
  ${media.mobile`
    padding: 10px 2px;
  `}
`;
export const SettingsInnerContainer = styled.div`
  display: flex;
  pointer-events: all;
  background: inherit;
  box-shadow: ${(props) => props.theme.sortOfSubtleBoxShadow};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  padding: 0.75em;
  width: 100%;
  align-items: center;
  ${media.mobile`
    font-size: 0.8em;
    flex-wrap: wrap;
  `}
`;
export const SettingsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 0.65em;
  animation: fadeIn 0.5s;
  box-sizing: border-box;
  position: ${(props) => (props.position ? props.position : "relative")};
  z-index: 3;
  pointer-events: none;
  background: ${(props) =>
    props.primaryColor ? props.primaryColor : props.theme.grey3};
`;
export const Layer = styled.div`
  position: fixed;
  animation: fadeIn 0.2s;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  background: rgba(0, 0, 0, 0.5);
`;
export const Container = styled.div`
  height: 1.5em;
  width: ${(props) => (props.circle ? "1.5em" : props.wide ? "100%" : "auto")};
  border-radius: 2em;
  animation: ${(props) => (props.animation ? props.animation : "none")};
  cursor: pointer;
  position: relative;
  user-select: none;
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;
  transition: ${(props) => props.theme.defaultTransition};
  align-items: center;
  margin: 0.5em;
  min-width: ${(props) => (props.circle ? "auto" : "4em")};
  text-transform: uppercase;
  padding: 1.74em;
  background: ${(props) =>
    props.primaryColor
      ? props.primaryColor
      : props.active
      ? props.theme.grey6
      : "rgba(0,0,0,0.45)"};
  color: ${(props) =>
    props.textColor
      ? props.textColor
      : props.active
      ? props.theme.grey1
      : "white"};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : props.theme.notSubtleBoxShadow};
  svg {
    min-width: ${(props) => (props.svgMinSize ? props.svgMinSize : "1em")};
    min-height: ${(props) => (props.svgMinSize ? props.svgMinSize : "1em")};
    height: ${(props) => (props.svgSize ? props.svgSize : "1em")};
    margin-right: ${(props) =>
      props.svgMarginRight
        ? props.svgMarginRight
        : props.circle
        ? "0.25em"
        : "0.75em"};
    margin-left: ${(props) => (props.circle ? "0.25em" : "0")};
  }
  &:hover {
    box-shadow: ${(props) => props.theme.notSubtleBoxShadow};
    color: ${(props) =>
      props.hoverTextColor ? props.hoverTextColor : props.theme.grey1};
    background: ${(props) =>
      props.hoverBackgroundColor
        ? props.hoverBackgroundColor
        : props.theme.grey6};
    svg {
      fill: ${(props) =>
        props.hoverTextColor
          ? props.hoverTextColor
          : props.active
          ? "auto"
          : props.theme.grey1};
    }
  }
  &:active {
    transform: translateY(-2px);
    background: white;
  }
  ${media.mobile`
    height: auto;
    max-height: 4em;
    margin: 0.25em;
    align-self: ${(props) =>
      props.mobileAlign ? props.mobileAlign : "stretch"};
    svg {
      margin-bottom: 0;
      margin-top: 0 !important;
    }
    .iconCaret {
      display: none;
    }
  `};
`;

export const TextInput = styled.input`
  padding: 1em;
  font-size: 1em;
  font-weight: bold;
  margin: 0 0 1em 0;
  outline: none;
  text-transform: none;
  width: 100%;
  border: 1px solid ${(props) => props.theme.grey4};
  box-sizing: border-box;
  &::placeholder {
    color: ${(props) => props.theme.grey4};
  }
`;

export const DropdownOptions = styled.div`
  box-shadow: ${(props) => props.theme.notSubtleBoxShadow};
  position: absolute;
  animation: fadeIn 0.25s;
  cursor: auto;
  background: ${(props) =>
    props.primaryColor ? props.primaryColor : props.theme.grey2};
  border-radius: 2em;
  overflow: hidden;
  top: 4em;
  left: ${(props) => (props.left ? props.left : "auto")};
  right: ${(props) => (props.right ? props.right : "auto")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "10em")};
  width: fit-content;
  z-index: 1;
  max-height: 50em;
  overflow: auto;
  ${media.mobile`
    top: 5em;
    min-width: 60vw;
    div {
      margin: 0.25em 0;
    }
  `};
`;
export const Mask = styled.div`
  background: rgba(0, 0, 0, 0.1);
  padding: 0.5em;
  display: flex;
  border-radius: 0.5em;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
export const ChildMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
`;
export const TooltipText = styled.div`
  background: #393e42;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "200px")};
  text-align: center;
  animation: fadeIn 0.5s;
  color: white;
  margin: 0.25em;
  padding: 0.5em;
  border-radius: 0.5em;
  font-size: 0.8em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .keyboardShortcut {
    display: flex;
    margin-left: 0.5em;
    padding: 0.25em 0.5em;
    border-radius: 0.25em;
    background: gray;
  }
`;
export const Option = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  svg {
    padding-right: 0.5em;
  }
`;
export const Title = styled.h2`
  font-weight: bold;
  width: 100%;
  padding: 0 0.5em;
  color: ${(props) => props.theme.grey2};
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : "nowrap")};
`;
export const LandingPageTitle = styled.h3`
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  padding: 0 0.5em;
  animation: fadeIn 0.5s;
  min-width: fit-content;
`;
export const H3 = styled.h3`
  color: ${(props) => (props.color ? props.color : props.theme.grey2)};
  margin: 0;
  padding: 1em;
  ${media.mobile`
    width: 100%;
    text-align: center;
  `}
`;

export const WhiteBox = styled.div`
  animation: fadeIn 1s;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.white};
  border-radius: 0.5em;
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0.5em;
  margin-bottom: 1em;
  ${media.mobile`
    justify-content: center;
  `};
`;
export const Img = styled.img`
  transition: ${(props) => props.theme.defaultTransition};
  user-select: none;
  max-width: 2.5em;
  background: white;
  min-width: 1em;
  height: ${(props) => (props.large ? "2.5em" : "1em")};
  padding-bottom: 0;
  margin-right: 0.5em;
  opacity: 0.7;
  border: ${(props) =>
    props.border ? `1px solid ${(props) => props.theme.grey2}` : "none"};
  object-fit: ${(props) => (props.border ? "fill" : "contain")};
  ${media.mobile`
    opacity: 1;
  `};
`;
const ReTooltipContainer = styled.div`
  border-radius: 2em;
  padding: 0.5em 1em;
  background: white;
  font-size: 0.8em;
  animation: fadeIn 0.5s;
`;
const ReTooltipText = styled.div`
  font-weight: bold;
`;

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length && payload[0].payload) {
    return (
      <ReTooltipContainer
        style={{
          background: payload[0].payload.fill
            ? payload[0].payload.fill
            : `rgb${payload[0].payload.primary_color}`,
        }}
      >
        <ReTooltipText
          style={{
            color: payload[0].payload.stroke
              ? payload[0].payload.stroke
              : textColor(payload[0].payload.primary_color),
          }}
        >
          {payload[0].payload.name}
        </ReTooltipText>
      </ReTooltipContainer>
    );
  }

  return null;
};

export const Glow = styled.div`
  box-shadow: 0px 0px 30px 40px ${(props) => props.theme.veryLightGrey};
  width: 100%;
  bottom: 0;
  z-index: 1;
  transition: ${(props) => props.theme.defaultTransition};
  position: absolute;
  opacity: ${(props) => (props.hide ? 0 : 1)};
`;

export const MainPageWrapper = styled.div`
  overflow-y: visible;
  height: 100%;
  position: relative;
`;
export const MenuLeft = styled.div`
  justify-content: flex-start;
  display: flex;
  align-items: center;
  animation: fadeIn 1s;
  input {
    display: none;
  }
  ${media.mobile`
    flex-wrap: wrap;
    font-size: 0.8em;
    width: 100%;
    justify-content: center;
  `};
`;
export const MenuText = styled.span`
  animation: fadeIn 1s;
  margin: 0 0.5em;
  font-size: 1.5em;
  padding: 0;
  font-weight: bold;
  color: #ffffff;
`;
export const Divider = styled.div`
  border-left: 1px solid ${(props) => props.theme.grey5};
  margin-left: 1em;
  padding-left: 1em;
  height: 100%;
`;
export const Dots = styled.div`
  background-image: radial-gradient(
    rgba(255, 255, 255, 0.15) 0.5px,
    rgba(0, 0, 0, 0.05) 0.5px
  );
  background-size: 15px 15px;
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;
export const TrendDot = styled.div`
  border-radius: 50%;
  min-height: 0.8em;
  min-width: 0.8em;
  margin-right: 0.5em;
`;
export const Name = styled.p`
  margin: 0;
  width: 100%;
  min-width: fit-content;
  text-align: left;
`;
export const MenuName = styled.div`
  user-select: none;
  padding: 0.25em;
  font-weight: bold;
  text-align: left;
`;
export const MenuContainer = styled.div`
  display: flex;
  width: ${(props) => (props.onboarding ? "100%" : "auto")};
  margin: ${(props) => (props.full ? "0" : "0 0.25em")};
  animation: fadeIn 0.5s;
  height: ${(props) => (props.height ? props.height : "unset")};
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  padding: ${(props) => (props.height ? "0 0.6em" : "0.6em")};
  cursor: ${(props) => (props.onboarding ? "auto" : "pointer")};
  color: ${(props) => (props.active ? props.theme.white : props.theme.grey2)};
  border-radius: ${(props) => (props.full ? "0" : "2em")};
  transition: ${(props) => props.theme.fastTransition};
  background: ${(props) =>
    props.noHover ? "none" : props.active ? props.theme.grey2 : "none"};
  svg {
    transition: ${(props) => props.theme.fastTransition};
    margin: ${(props) => (props.full ? "0 0.25em" : 0)};
    min-width: 1em;
    fill: ${(props) =>
      props.whiteIcon || props.active
        ? props.theme.white
        : props.fill
        ? props.fill
        : props.theme.grey2};
  }
  &:hover {
    .shortcut-keys {
      background: ${(props) => props.theme.grey2};
    }
    color: ${(props) => (props.active ? props.theme.white : props.theme.grey0)};
    .FaAngleDown {
      transform: ${(props) => (props.active ? "none" : "translateY(2px)")};
    }
    svg {
      fill: ${(props) =>
        props.whiteIcon || props.active
          ? props.theme.white
          : props.fillHover
          ? props.fillHover
          : props.theme.grey0};
    }
  }
  &:active {
    transform: ${(props) => (props.onboarding ? "none" : "translateY(-2px)")};
  }
  ${media.mobile`
    margin: auto;
    padding: 0.5em;
  `};
`;
export const CopyButton = styled.button`
  background: ${(props) =>
    props.copied ? props.theme.midGreen : props.theme.midBlue};
  color: white;
  transition: ${(props) => props.theme.defaultTransition};
  padding: 0.75em;
  font-weight: bold;
  min-width: 5em;
  cursor: pointer;
  border: none;
  font-family: ${(props) => props.theme.font};
  font-size: 1em;
  border-left: 1px solid
    ${(props) => (props.copied ? props.theme.midGreen : props.theme.grey4)};
  &:hover {
    background: ${(props) =>
      props.copied ? props.theme.midGreen : props.theme.midBlue};
  }
  &:active {
    background: ${(props) => props.theme.midGreen};
  }
`;
export const LoginOption = styled.div`
  border: 1px solid ${(props) => props.theme.grey4};
  border-radius: 0.25em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.25em;
  padding: 1em;
  width: 100%;
  color: ${(props) => props.theme.grey2};
  cursor: pointer;
  transition: ${(props) => props.theme.fastTransition};
  img {
    width: 1.25em;
    height: 1.25em;
    margin-right: 1em;
  }
  svg {
    width: 1.25em;
    height: 1.25em;
    margin-right: 1em;
    fill: ${(props) => props.theme.grey2};
  }
  &:hover {
    background: ${(props) => props.theme.grey6};
    color: ${(props) => props.theme.grey1};
  }
  &:active {
    background: ${(props) => props.theme.grey5};
  }
`;

export const FullWidth = styled.div`
  width: 100%;
  display: ${props => props.display};
`;