import { PureComponent } from "react";

export default class Arcade extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          paddingBottom:
            this.state.windowWidth < 1000
              ? "calc(57.20899470899471% + 41px)"
              : this.props.width || "615px",
          height: 0,
          maxWidth: "1000px",
          width: "100%",
        }}
      >
        <iframe
          src={`https://demo.arcade.software/${this.props.arcadeId}?embed`}
          frameBorder="0"
          loading="lazy"
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            colorScheme: "light",
          }}
          title="Starred Views"
        />
      </div>
    );
  }
}
