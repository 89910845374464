import { PureComponent } from "react";
import styled, { withTheme } from "styled-components";

import { FaCircleNotch } from "react-icons/fa";

const Wrapper = styled.div`
  animation: fadeIn 1s;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "100%")};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: ${(props) => (props.padding ? props.padding : "1em")};
  text-align: center;
  p {
    padding: 1em 0;
    font-weight: bold;
    width: 100%;
    color: ${(props) => (props.color ? props.color : props.theme.grey3)};
  }
  svg {
    animation: 1s spin infinite;
    margin: ${(props) => (props.svgMargin ? props.svgMargin : "0 1em")};
    opacity: 0.7;
    fill: ${(props) => (props.color ? props.color : props.theme.grey3)};
  }
`;

const Container = styled.div`
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

class Loader extends PureComponent {
  componentDidMount() {
    if (this.props.mountAction) {
      this.props.mountAction();
    }
  }
  render() {
    return (
      <Wrapper
        height={this.props.height}
        width={this.props.width}
        padding={this.props.padding}
        color={this.props.color}
        margin={this.props.margin}
        svgMargin={this.props.svgMargin}
      >
        <Container
          style={{
            padding: this.props.whiteCircle ? "1em" : 0,
            height: this.props.whiteCircle ? "3em" : "auto",
            width: this.props.whiteCircle ? "3em" : "auto",
            backgroundColor: this.props.whiteCircle
              ? this.props.theme.white
              : "auto",
          }}
        >
          {this.props.text ? (
            <p>{this.props.text}</p>
          ) : this.props.item ? (
            <p>{`Finding more ${this.props.item}...`}</p>
          ) : null}
          <FaCircleNotch color={this.props.color} size={this.props.size} />
        </Container>
      </Wrapper>
    );
  }
}

export default withTheme(Loader);
