var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { LOGOUT } from "@redux/actions/logout";
import { startAppListening } from "@redux/listenerMiddleware";
import { createSlice } from "@reduxjs/toolkit";
import { questionCodeFiltersApplied } from "@redux/slices/adminPortalSlice";
import { Modals } from "screens/modal/Modal.constants";
var HIDE_MODAL_ACTIONS = [
    LOGOUT,
    questionCodeFiltersApplied.type,
    "RESET_SELECT_MULTIPLE_PHOTOS",
    "RESET_SELECT_MULTIPLE_FLYERS",
    "PRICING_FILTER_APPLY_FEATURES",
    "PRICING_FILTER_APPLY_DATE",
    "PRICING_FILTER_APPLY_LOCATION",
    "PRICING_FILTER_APPLY_PRICE",
    "PRICING_FILTER_APPLY_POSITIONS",
    "RESET_SELECT_MULTIPLE_PLANOGRAMS",
    "FEED_FILTERS_CHANGE_SORT",
    "FLYERS_FILTERS_CHANGE_SORT",
    "STAR_CHECK_ON",
    "PLANOGRAM_FILTERS_CHANGE_SORT",
    "HIDE_VIEWER",
    "SHOW_VIEWER",
    "APPLY_PLANOGRAM_FILTERS",
    "WHITELIST_EMAIL_DOMAIN_DATA",
    "SWITCH_PRIVATE_SPACE",
    "FLYER_FILTERS_APPLY_NEW_CHANGES",
    "@@router/LOCATION_CHANGE",
];
var SHARE_LINK_ACTIONS = [
    "SHORTEN_SHARE_LINK_DATA",
    "SHORTEN_FLYER_SHARE_LINK_DATA",
    "SHORTEN_PLANOGRAM_SHARE_LINK_DATA",
];
var initialState = {
    isOpen: false
};
var modalSlice = createSlice({
    name: "modal",
    initialState: initialState,
    reducers: {
        showModal: function (state, action) {
            state.isOpen = true;
            state.isPersistent = action.payload.isPersistent;
            state.component = action.payload.component;
            state.data = action.payload.data;
        },
        hideModal: function (state) {
            state.isOpen = false;
            state.isPersistent = false;
        },
        clearModal: function () { return initialState; }
    },
    extraReducers: function (builder) {
        builder
            .addCase("GENERATE_WORD_CLOUD_DATA", function (state, action) {
            if (state.component === Modals.WORD_CLOUD) {
                return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), action.data) });
            }
        })
            .addCase("USER_LOGS_IN_DATA", function (state, action) {
            if (state.isOpen && action.data.shelf_token) {
                modalSlice.caseReducers.hideModal(state);
            }
        })
            .addMatcher(function (action) { return SHARE_LINK_ACTIONS.includes(action.type); }, function (state, action) {
            if (state.component === Modals.SHARE && !state.data.isPdf) {
                state.data.shortUrl = action.data.short_url;
            }
        })
            .addMatcher(function (action) { return HIDE_MODAL_ACTIONS.includes(action.type); }, modalSlice.caseReducers.hideModal);
    }
});
export var showModal = (_a = modalSlice.actions, _a.showModal), hideModal = _a.hideModal, clearModal = _a.clearModal;
export default modalSlice.reducer;
startAppListening({
    type: "API_CALL_FAILURE",
    effect: function (action, listenerApi) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (action.error.status === 403) {
                listenerApi.dispatch(showModal({
                    component: Modals.BIG_ANNOUNCEMENT,
                    isPersistent: true,
                    data: {
                        message: "Access Restricted",
                        body: "Your access has been temporarily restricted due to repeated unusual activity.\n\nIf you think this was a mistake, contact your Shelfgram customer success manager.",
                    }
                }));
            }
            return [2 /*return*/];
        });
    }); }
});
