import { GlobalStyles, darkMode, lightMode } from "./themes";
import { PureComponent } from "react";

import { ThemeProvider } from "styled-components";
import { connect } from "react-redux";

class ConnectedThemeProvider extends PureComponent {
  render() {
    return (
      <ThemeProvider
        theme={
          this.props.dataUser && this.props.dataUser.dark_mode
            ? darkMode
            : lightMode
        }
      >
        <GlobalStyles />
        {this.props.children}
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
});

export default connect(mapStateToProps, null)(ConnectedThemeProvider);
