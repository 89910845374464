import { Component } from "react";
import * as Sentry from "@sentry/react";
import MainCTA from "components/buttons/main/index";
import { awsPrefix } from "constants/variables/index";
import { push } from "redux-first-history";
import { store } from "@redux/store";
import styled from "styled-components";

const Wrapper = styled.div`
  animation: fadeIn 1s;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 1em;
  overflow: hidden;
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
  border-radius: 1em;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1em;
  background: white;
`;
const Img = styled.img`
  height: 15em;
  display: block;
  margin: auto;
`;
const H1 = styled.h1`
  color: ${(props) => props.theme.grey2};
  margin: 0;
`;
const P = styled.p`
  color: ${(props) => props.theme.grey2};
  margin: 0;
`;
const Code = styled.code`
  color: ${(props) => props.theme.grey3};
  padding: 1em;
  margin: 1em;
  border-radius: 2em;
  width: 100%;
  text-align: center;
  border: 1px solid ${(props) => props.theme.grey4};
`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, random: 0 };
    this.backbutton = this.backbutton.bind(this);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: error };
  }

  componentDidCatch(error, info) {
    const min = 0;
    const max = 12;
    const rand = Math.floor(min + Math.random() * (max - min));
    this.setState((prevState) => ({
      hasError: error,
      random: prevState.random + rand,
    }));
    if (process.env.NODE_ENV === "production") {
      const menu = document.getElementById("menu");
      if (menu) {
        menu.setAttribute("style", "display:none;");
      }
    }
    // Log the error to Sentry
    Sentry.captureException(error);
    if (error && error.name) {
      console.log("> ERROR NAME - ", error.name);
      console.log("> ERROR MESSAGE - ", error.message);
      if (error.message === "Invalid offset NaN specified") {
        console.log("Invalid offset NaN specified");
        window.location.reload();
      }
    } else {
      console.log("> ERROR MESSAGE - None");
    }
    if (error && error.name === "ChunkLoadError") {
      this.pushBackToLogin();
    } else if (error && error.name === "InvalidTokenError") {
      this.pushBackToLogin();
    }
  }

  pushBackToLogin() {
    this.clearStorage().then((x) => {
      store.dispatch(push("/login"));
    });
  }

  backbutton() {
    this.clearStorage().then((x) => {
      this.setState({ hasError: false, random: 0 }, location.reload(true));
    });
  }

  async clearStorage() {
    await localStorage.clear();
  }

  render() {
    if (this.state.hasError) {
      return (
        <Wrapper>
          <Container>
            <H1>Uh oh! Something went wrong</H1>
          </Container>
          <Container>
            <P>Our team has been notified of the issue:</P>
            {this.state.hasError ? (
              <>
                {this.state.hasError.cause && (
                  <Code>{this.state.hasError.cause}</Code>
                )}
                <Code>{this.state.hasError.name}</Code>
                <Code>{this.state.hasError.message}</Code>
              </>
            ) : null}
          </Container>
          <Container>
            <Img src={`${awsPrefix}errors/${this.state.random}.png`} />
          </Container>
          <Container>
            <MainCTA text="Take me back" clickAction={this.backbutton} />
          </Container>
        </Wrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
