import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    try {
      if (window._hsq) {
        window._hsq.push(["setPath", location.pathname]);
        window._hsq.push(["trackPageView"]);
      } else {
        console.error("HubSpot tracking script not loaded");
      }
    } catch (error) {
      console.error("Error tracking page view:", error);
    }
  }, [location]);

  return null; // This component doesn't render anything
};

export default PageViewTracker;
